enum AlertType {
  Error = 'error',
  Note = 'note',
  Alert = 'alert',
  Success = 'success'
}

type AlertProps = {
  type: AlertType;
  content: string;
  noBorder: boolean;
  children?: React.ReactNode | string;
};

const colors = {
  [AlertType.Error]: {
    bg: 'bg-red-50',
    text: 'text-red-700',
    border: 'border border-red-400'
  },
  [AlertType.Note]: {
    bg: 'bg-blue-50',
    text: 'text-blue-700',
    border: 'border border-blue-400'
  },
  [AlertType.Alert]: {
    bg: 'bg-yellow-50',
    text: 'text-yellow-700',
    border: 'border border-yellow-400'
  },
  [AlertType.Success]: {
    bg: 'bg-green-50',
    text: 'text-green-700',
    border: 'border border-green-400'
  }
};

export default function Alert({
  type,
  content,
  noBorder = false,
  children
}: AlertProps) {
  return (
    <div
      className={`flex p-4 mb-6 ${colors[type]?.bg} ${
        !noBorder ? `${colors[type]?.border} rounded-md` : ''
      }`}
    >
      <div className={`w-full text-sm ${colors[type]?.text}`}>{children}</div>
    </div>
  );
}
