import { CheckIcon, XIcon } from '@heroicons/react/solid';
import Button from '../ui/Button';
import { plans, Product, ProductPrice } from './config';

type PricingBlockProps = {
  product: Product;
  price: ProductPrice;
  billingInterval: string;
  disabled?: boolean;
  isLoading: boolean;
  callToAction: string;
  handleCheckout: Function;
  variant: 'small' | 'default';
};

export default function PricingBlock({
  product,
  price,
  disabled = false,
  isLoading,
  callToAction,
  handleCheckout,
  billingInterval,
  variant = 'default'
}: PricingBlockProps) {
  const priceString = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: price.currency,
    minimumFractionDigits: 0
  }).format(price.unit_amount / 100);

  const productFeatures = plans[product.name];

  return (
    <div
      key={product.name}
      className={`relative bg-white border border-gray-200 rounded-2xl shadow-sm flex flex-col ${
        variant === 'small' ? 'px-4 py-8' : 'p-8'
      }`}
    >
      <div className="flex-1">
        <h3 className="text-xl font-semibold text-gray-900">{product.name}</h3>
        {productFeatures.label && (
          <p className="absolute top-0 py-1.5 px-4 bg-blue-500 rounded-full text-xs font-semibold uppercase tracking-wide text-white transform -translate-y-1/2">
            {productFeatures.label}
          </p>
        )}
        <p className="mt-4 flex items-baseline text-gray-900">
          <span
            className={`font-extrabold tracking-tight ${
              variant === 'small' ? 'text-3xl' : 'text-5xl'
            }`}
          >
            {priceString}
          </span>
          <span className="ml-1 text-xl font-semibold">{billingInterval}</span>
        </p>
        {billingInterval === 'year' && product.name !== 'Free' && (
          <p className="mt-2 flex items-baseline">
            <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800">
              {variant === 'small'
                ? 'Save 2 months'
                : 'Save 2 months with yearly billing'}
            </span>
          </p>
        )}
        <p className="mt-6 text-gray-500">{product.description}</p>

        <ul role="list" className="mt-6 space-y-6">
          {productFeatures.features.map(({ id, included, name, tag }) => (
            <li key={id} className="flex">
              {included ? (
                <CheckIcon
                  className="flex-shrink-0 w-6 h-6 text-blue-500"
                  aria-hidden="true"
                />
              ) : (
                <XIcon
                  className="flex-shrink-0 w-6 h-6 text-gray-500"
                  aria-hidden="true"
                />
              )}
              <span className="ml-3 text-gray-500">{name}</span>
              {tag && (
                <span className="inline-flex items-center px-2 py-0.5 rounded-full text-xs text-center font-medium bg-blue-100 text-blue-800 ml-1">
                  {tag}
                </span>
              )}
            </li>
          ))}
        </ul>
      </div>

      {callToAction && handleCheckout && (
        <Button
          variant="primary"
          type="button"
          disabled={disabled}
          loading={isLoading}
          onClick={() => handleCheckout(price.id)}
          className="mt-8 block w-full rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-gray-900"
        >
          {callToAction}
        </Button>
      )}
    </div>
  );
}
