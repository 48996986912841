const Input = (props) => {
  const {
    className,
    children,
    onChange,
    name,
    label,
    required = false,
    ...rest
  } = props;

  const handleOnChange = (e) => {
    if (onChange) {
      onChange(e.target.value);
    }
    return null;
  };

  return (
    <>
      {label && <div className="flex justify-between mb-1">
        <label
          htmlFor={name}
          className="block text-sm font-medium text-gray-700"
        >
          {label}
        </label>
        {!required && (
          <span className="pb-4 sm:pb-0 text-gray-600 text-sm" id="optional">
            Optional
          </span>
        )}
      </div>}

      <input
        onChange={handleOnChange}
        // autoComplete="off"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck="false"
        {...rest}
        className={`block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm ${className}`}
      />
    </>
  );
};

export default Input;
