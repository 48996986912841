import Link from 'next/link';
import { Disclosure } from '@headlessui/react';
import {
  CollectionIcon,
  ShareIcon,
  UsersIcon,
  ChevronDownIcon
} from '@heroicons/react/outline';

import Pricing from '../components/Pricing';
import { getActiveProductsWithPrices } from '../utils/supabase-client';
import Dev from '../components/icons/Dev';
import Hashnode from '../components/icons/Hashnode';
import Substack from '../components/icons/Substack';
import Medium from '../components/icons/Medium';
import RSS from '../components/icons/RSS';
import HackerNews from '../components/icons/HackerNews';
import Button from '../components/ui/Button';
import { SignUp } from './signup';
import { useUser } from '../utils/useUser';
import router from 'next/router';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const transferFeatures = [
  {
    id: 1,
    name: 'Connect your platforms',
    description:
      'Repost to and from all supported blogging platforms, including your own RSS feed. Simply provide your integration details and start reposting.',
    icon: UsersIcon
  },
  {
    id: 2,
    name: 'Import your content',
    description:
      "Import posts from your connected blogging platforms or RSS feed, whether it's written in HTML or Markdown. You can repost HTML content to Markdown, and vice-versa.",
    icon: CollectionIcon
  },
  {
    id: 3,
    name: 'Repost everywhere',
    description:
      'With Reposted you can repost your content from one interface, to all supported blogging platforms. Maximize the reach of your posts and explore new audiences.',
    icon: ShareIcon
  }
];

const faqs = [
  {
    id: 1,
    question: 'How can I repost content with Reposted?',
    answer:
      'You can connect your favorite blogging platform and use it as the single source of truth to repost it to any other platform. You can synchronize changes you make in one blogging platform to other blogger platforms.'
  },
  {
    id: 2,
    question: 'What platforms are supported?',
    answer:
      'At this moment, you can repost content to and from DEV.to, Medium, Substack, Hashnode and your own RSS feed. Furter integrations are coming soon, and available upon request.'
  },
  {
    id: 3,
    question: 'Is Reposted free to use?',
    answer:
      'You can connect up to 2 blogging platforms with our free plan. If you want to connect more blogging platforms or your RSS feed, you need to switch to our premium plan.'
  }
  // More questions...
];

export default function PricingPage({ products }) {
  const { userDetails } = useUser();
  return (
    <>
      <div className="relative bg-gray-50 overflow-hidden">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <main>
            <div className="mx-auto max-w-7xl">
              <div className="lg:grid lg:grid-cols-12 lg:gap-8">
                <div className="px-4 sm:px-6 sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left lg:flex lg:items-center">
                  <div>
                    <h1 className="mt-4 text-4xl tracking-tight font-extrabold text-black sm:mt-5 sm:leading-none lg:mt-6 lg:text-5xl xl:text-6xl">
                      <span className="md:block">Write once,</span>
                      <span className="text-blue-500 md:block">
                        repost everywhere
                      </span>
                    </h1>
                    <p className="mt-3 text-base text-gray-600 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                      With Reposted you can post and synchronize all your
                      content on major blogging platforms for developers.
                      Maximize the reach of your blog posts by start using
                      Reposted today!
                    </p>
                    <p className="mt-8 text-sm text-gray-600 uppercase tracking-wide font-semibold sm:mt-10">
                      Repost to
                    </p>
                    <div className="w-full sm:mx-auto sm:max-w-lg lg:ml-0 mt-4">
                      <div className="flex items-center justify-start">
                        <div className="mr-6">
                          <Dev height={30} />
                        </div>
                        <div className="mr-6">
                          <Hashnode height={20} />
                        </div>
                        <div className="mr-6">
                          <Substack height={30} />
                        </div>
                        <div className="mr-6">
                          <Medium height={20} />
                        </div>
                        <div>
                          <RSS height={40} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {!userDetails && (
                  <div className="mt-16 sm:mt-24 lg:mt-0 lg:col-span-6">
                    <div className="bg-white sm:max-w-md sm:w-full shadow sm:mx-auto sm:rounded-lg sm:overflow-hidden">
                      <SignUp isEmbedded />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </main>
        </div>
      </div>

      <div className="relative bg-white pt-16 pb-32 overflow-hidden">
        {/* @ts-ignore */}
        <a name="features"></a>
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
          <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
            <div>
              <h2 className="text-3xl font-extrabold text-black sm:text-4xl">
                Start reposting today 🔥
              </h2>

              <div className="mt-12">
                <div className="flow-root">
                  <ul role="list" className="-mb-12">
                    {transferFeatures.map((item, eventIdx) => (
                      <li key={item.id}>
                        <div className="relative pb-16">
                          {eventIdx !== transferFeatures.length - 1 ? (
                            <>
                              <span
                                className="absolute top-6 left-6 -ml-px h-full w-0.5 bg-gray-200"
                                aria-hidden="true"
                              />
                            </>
                          ) : null}
                          <div className="relative flex space-x-3">
                            <div>
                              <div className="absolute flex items-center justify-center h-12 w-12 rounded-full bg-gray-300 font-extrabold text-black">
                                {item.id}
                              </div>
                            </div>
                            <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                              <div>
                                <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                                  {item.name}
                                </p>
                                <p className="mt-2 ml-16 text-base text-gray-500">
                                  {item.description}
                                </p>

                                {item.id === 1 && (
                                  <div className="mt-4 ml-16">
                                    <div className="flex items-center justify-start">
                                      <div className="mr-6">
                                        <Dev height={30} />
                                      </div>
                                      <div className="mr-6">
                                        <Hashnode height={20} />
                                      </div>
                                      <div className="mr-6">
                                        <Substack height={30} />
                                      </div>
                                      <div className="mr-6">
                                        <Medium height={20} />
                                      </div>
                                      <div>
                                        <RSS height={40} />
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="mt-8">
                  <Button
                    variant="primary"
                    className="text-base"
                    onClick={() => router.push('/signup')}
                  >
                    Get started
                  </Button>
                </div>
              </div>
            </div>
            <div className="mt-8 border-t border-gray-200 pt-6">
              <blockquote>
                <div>
                  <p className="text-base text-gray-500">
                    &ldquo;My work as a Developer Advocate has become so much
                    easier, I no longer have to manually copy and paste content
                    on different platforms.&rdquo;
                  </p>
                </div>
                <footer className="mt-3">
                  <div className="flex items-center space-x-3">
                    <div className="flex-shrink-0">
                      <img
                        className="h-10 w-10 rounded-full"
                        src="https://pbs.twimg.com/profile_images/1169941475372781569/-10BXZ3k_400x400.jpg"
                        alt=""
                      />
                    </div>
                    <div className="text-base font-medium text-gray-700">
                      Roy Derks
                    </div>
                  </div>
                </footer>
              </blockquote>
            </div>
          </div>
          <div className="mt-12 sm:mt-16 lg:mt-0">
            <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
              <img
                className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                src="/app-screenshot.png"
                alt="Inbox user interface"
              />
            </div>
          </div>
        </div>
      </div>
      <Pricing products={products} />
      <div className="bg-white">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-24 lg:px-8">
          <div className="lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
            <div>
              <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
                Post and synchronize all <br />
                your content
              </h2>
              <p className="mt-3 max-w-3xl text-lg text-gray-500">
                With Reposted you can post and synchronize all your content on
                major blogging platforms for developers. Or repost from your
                own, self-hosted blog using RSS!
              </p>

              <div className="flex mt-8 items-center justify-start">
                <Button variant="primary" className="text-base mr-3">
                  Sign up
                </Button>
                <Link href="mailto:hello@hackteam.io">
                  <a className="whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900">
                    Request an integration
                  </a>
                </Link>
              </div>
            </div>
            <div className="mt-8 grid grid-cols-2 gap-6 md:grid-cols-3 lg:mt-0 lg:grid-cols-2">
              <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50 rounded-xl shadow">
                <Dev height={60} />
              </div>
              <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50 rounded-xl shadow">
                <Hashnode height={60} />
              </div>
              <div className="relative col-span-1 flex justify-center py-8 px-8 bg-gray-50 rounded-xl shadow">
                <Substack height={60} />
              </div>
              <div className="relative col-span-1 flex justify-center py-8 px-8 bg-gray-50 rounded-xl shadow">
                <Medium height={60} />
                {/* <span className="absolute left-2 top-2 inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-green-200 text-green-800">
                  Coming soon
                </span> */}
              </div>
              <div className="relative col-span-1 flex justify-center py-8 px-8 bg-gray-50 rounded-xl shadow">
                <RSS height={60} />
                {/* <span className="absolute left-2 top-2 inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-green-200 text-green-800">
                  Coming soon
                </span> */}
              </div>
              <div className="relative col-span-1 flex justify-center py-8 px-8 bg-gray-50 rounded-xl shadow">
                <HackerNews height={60} />
                <span className="absolute left-2 top-2 inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-green-200 text-green-800">
                  Coming soon
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gray-50">
        {/* @ts-ignore */}
        <a name="faq"></a>
        <div className="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
          <div className="max-w-3xl mx-auto divide-y-2 divide-gray-200">
            <h2 className="text-3xl font-extrabold text-black sm:text-center sm:text-4xl">
              Frequently asked questions
            </h2>
            <dl className="mt-8 space-y-6 divide-y divide-gray-200">
              {faqs.map((faq) => (
                <Disclosure as="div" key={faq.question} className="pt-6">
                  {({ open }) => (
                    <>
                      <dt className="text-lg">
                        <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                          <span className="font-medium text-gray-900">
                            {faq.question}
                          </span>
                          <span className="ml-6 h-7 flex items-center">
                            <ChevronDownIcon
                              className={classNames(
                                open ? '-rotate-180' : 'rotate-0',
                                'h-6 w-6 transform'
                              )}
                              aria-hidden="true"
                            />
                          </span>
                        </Disclosure.Button>
                      </dt>
                      <Disclosure.Panel as="dd" className="mt-2 pr-12">
                        <p className="text-base text-gray-500">{faq.answer}</p>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              ))}
            </dl>
            <div className="mt-6 py-8 divide-y divide-gray-200 flex justify-center">
              <Button variant="primary" className="text-base">
                Other question? Contact us!
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export async function getStaticProps() {
  const products = await getActiveProductsWithPrices();

  return {
    props: {
      products
    },
    revalidate: 60
  };
}
