import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import Alert from '../components/ui/Alert';
import Button from '../components/ui/Button';
import Input from '../components/ui/Input';
import Logo from '../components/icons/Logo';
import Twitter from '../components/icons/Twitter';
import Github from '../components/icons/GithubIcon';

import { updateUserName } from '../utils/supabase-client';
import { useUser } from '../utils/useUser';

export const SignUp = ({ isEmbedded = true }) => {
  const [user, setUser] = useState(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({
    type: '',
    content: ''
  });
  const router = useRouter();
  const { signUp } = useUser();

  const handleSignup = async (e) => {
    e.preventDefault();

    setLoading(true);
    setMessage({});
    const { error, user } = await signUp({ email, password });

    if (error) {
      setMessage({ type: 'error', content: error.message });
    } else {
      if (user) {
        await updateUserName(user, name);
        setUser(user);

        router.replace('/app/account');
      } else {
        setMessage({
          type: 'note',
          content: 'Check your email for the confirmation link.'
        });
      }
    }
    setLoading(false);
  };

  const handleOAuthSignUp = async (provider) => {
    setLoading(true);
    const { error, user } = await signUp({ provider });
    if (error) {
      setMessage({ type: 'error', content: error.message });
    } else {
      if (user) {
        await updateUserName(user, name);
        setUser(user);

        router.push('/app');
      } else {
        setMessage({
          type: 'note',
          content: 'Check your email for the confirmation link.'
        });
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (user && !isEmbedded) {
      router.push('/app');
    }
  }, [user, isEmbedded]);

  return (
    <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
      <div className="mb-8">
        <h2 className="text-center text-3xl font-semibold text-gray-900">
          Sign up for free
        </h2>
        <p className="mt-2 text-center text-sm text-gray-600">
          Or{' '}
          <Link href="/signin">
            <a className="font-medium text-blue-600 hover:text-blue-500">
              sign in to your account
            </a>
          </Link>
        </p>
      </div>

      {message?.type && <Alert type={message.type}>{message.content}</Alert>}

      <form onSubmit={handleSignup} className="">
        <div className="mb-4">
          <Input
            name="name"
            placeholder="Name"
            label="Name"
            onChange={setName}
            required
          />
        </div>
        <div className="mb-4">
          <Input
            type="email"
            name="email"
            placeholder="Email"
            label="Email"
            onChange={setEmail}
            required
          />
        </div>
        <div className="mb-4">
          <Input
            type="password"
            name="password"
            placeholder="Password"
            label="Password"
            onChange={setPassword}
            required
          />
        </div>
        <div className="pt-2 w-full flex flex-col">
          <Button
            variant="primary"
            type="submit"
            loading={loading}
            disabled={
              loading || !name.length || !email.length || !password.length
            }
          >
            Sign up
          </Button>
        </div>
      </form>

      <div className="mt-6">
        <div className="relative">
          <div className="absolute inset-0 flex items-center">
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-center text-sm">
            <span className="px-2 bg-white text-gray-500">
              Or continue with
            </span>
          </div>
        </div>

        <div className="mt-6 gap-3">
          {/* <div className="mt-6 grid grid-cols-2 gap-3"> */}
          {/* <div>
                <button
                  onClick={() => handleOAuthSignIn('github')}
                  className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                >
                  <span className="sr-only">Sign in with Github</span>
                  <Github />
                </button>
              </div> */}
          <div>
            <button
              onClick={() => handleOAuthSignUp('twitter')}
              className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            >
              <span className="sr-only">Sign in with Twitter</span>
              <Twitter />
            </button>
          </div>
        </div>
      </div>

      <div className="px-4 py-6 mt-8 bg-gray-50 border-t-2 border-gray-200 sm:px-10 -mb-8 -mx-4 sm:-mx-10">
        <p className="text-xs leading-5 text-gray-500">
          By signing up, you agree to our{' '}
          <Link href="/terms">
            <a className="font-medium text-gray-900 hover:underline">Terms</a>
          </Link>{' '}
          and{' '}
          <Link href="/privacy">
            <a className="font-medium text-gray-900 hover:underline">
              Privacy Policy
            </a>
          </Link>
          .
        </p>
      </div>
    </div>
  );
};

export default function SignUpPage() {
  return (
    <div className="min-h-screen bg-gray-50 flex flex-col items-center justify-start py-12 pb-0 sm:px-6 lg:px-8">
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <SignUp />
      </div>
    </div>
  );
}
