export default function HackerNews(props) {
  return (
    <svg viewBox="0 0 256 256" {...props} xmlns="http://www.w3.org/2000/svg">
      <path fill="#FB651E" d="M0 0h256v256H0z" />
      <path
        d="M119.374 144.746 75.433 62.432h20.081l25.848 52.092c.398.928.862 1.889 1.392 2.883.53.994.994 2.022 1.391 3.082.266.398.464.762.597 1.094.133.33.265.63.398.894a65.643 65.643 0 0 1 1.79 3.877c.53 1.26.993 2.42 1.39 3.48 1.061-2.254 2.221-4.673 3.48-7.257 1.26-2.585 2.552-5.27 3.877-8.053l26.246-52.092h18.69l-44.34 83.308v53.087h-16.9v-54.081Z"
        fill="#FFF"
      />
    </svg>
  );
}
