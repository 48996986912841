import { useRouter } from 'next/router';
import { useState } from 'react';
import { postData } from '../../utils/helpers';
import { getStripe } from '../../utils/stripe-client';
import { useUser } from '../../utils/useUser';
import PricingBlock from 'components/Pricing/PricingBlock';

export default function Pricing({ products, isEmbedded = false }) {
  const router = useRouter();
  const [billingInterval, setBillingInterval] = useState('month');
  const [priceIdLoading, setPriceIdLoading] = useState('');
  const { session, userLoaded, subscription } = useUser();

  const handleCheckout = async (price) => {
    setPriceIdLoading(price);

    if (!session) {
      return router.push('/signin');
    }

    try {
      const { sessionId } = await postData({
        url: '/api/create-checkout-session',
        data: { price },
        token: session.access_token
      });

      const stripe = await getStripe();
      stripe.redirectToCheckout({ sessionId });
    } catch (error) {
      return alert(error.message);
    } finally {
      setPriceIdLoading('');
    }
  };

  if (!products.length) return null;

  return (
    <section className={!isEmbedded && 'bg-gray-50'}>
      {/* @ts-ignore */}
      <a name="pricing"></a>
      <div
        className={`max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 ${
          !isEmbedded && 'py-8 sm:py-24'
        }`}
      >
        <div className="sm:flex sm:flex-col sm:align-center">
          {!isEmbedded && (
            <>
              <h1 className="text-3xl font-extrabold text-black sm:text-center sm:text-4xl">
                Pricing Plans
              </h1>
              <p className="my-6 text-base text-accents-6 sm:text-center sm:text-2xl max-w-2xl m-auto">
                Start reposting for free to one platform of your choice!
              </p>
            </>
          )}
          <div className="relative self-center bg-primary-2 rounded-lg p-0.5 flex border border-accents-0 bg-white">
            <button
              onClick={() => setBillingInterval('month')}
              type="button"
              className={`${
                billingInterval === 'month'
                  ? 'relative w-1/2 bg-accents-1 border-accents-0 shadow-sm text-white bg-yellow-500'
                  : 'ml-0.5 relative w-1/2 border border-transparent text-accents-4'
              } rounded-md m-1 py-2 text-sm font-medium whitespace-nowrap focus:outline-none focus:ring-2 focus:ring-yellow-600 focus:ring-opacity-50 focus:z-10 sm:w-auto sm:px-8`}
            >
              Monthly billing
            </button>
            <button
              onClick={() => setBillingInterval('year')}
              type="button"
              className={`${
                billingInterval === 'year'
                  ? 'relative w-1/2 bg-accents-1 border-accents-0 shadow-sm text-white bg-yellow-500'
                  : 'ml-0.5 relative w-1/2 border border-transparent text-accents-4'
              } rounded-md m-1 py-2 text-sm font-medium whitespace-nowrap focus:outline-none focus:ring-2 focus:ring-yellow-600 focus:ring-opacity-50 focus:z-10 sm:w-auto sm:px-8`}
            >
              Yearly billing
            </button>
          </div>
        </div>
        <div
          className={`mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-1 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0 xl:grid-cols-3 ${
            isEmbedded && 'text-sm'
          }`}
        >
          <PricingBlock
            variant={isEmbedded ? 'small' : 'default'}
            product={{
              id: 'free',
              name: 'Free',
              description:
                'Works for people that incidentally write blog posts and want to get more reach'
            }}
            price={{
              id: 'free',
              currency: 'usd',
              interval: 'month',
              unit_amount: 0
            }}
            billingInterval={billingInterval}
            isLoading={false}
            handleCheckout={() => router.push('/signin')}
            callToAction={!session && 'Signup for free'}
          />
          {products.map((product) => {
            const price = product.prices.find(
              (price) => price.interval === billingInterval
            );

            if (!price) return null;

            return (
              <PricingBlock
                key={price.id}
                variant={isEmbedded ? 'small' : 'default'}
                product={product}
                disabled={session && !userLoaded}
                price={price}
                billingInterval={billingInterval}
                isLoading={priceIdLoading === price.id}
                callToAction={
                  product.name === subscription?.prices?.products.name
                    ? 'Manage'
                    : !session
                    ? 'Subscribe'
                    : 'Upgrade'
                }
                handleCheckout={handleCheckout}
              />
            );
          })}
        </div>
      </div>
    </section>
  );
}
